import { ReactNode } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';

interface Props {
  className?: string;
  header: string;
  description: string;
  descriptionList?: string[];
  footer?: string | JSX.Element;
  children?: ReactNode;
}

const FeatureDescription = (props: Props) => {
  const { className, header, description, descriptionList, footer, children } =
    props;

  const { t } = useTranslation();

  const classes = classNames('feature-description', className);

  const footerContent =
    (typeof footer === 'string' && <Trans i18nKey={footer} />) || footer;

  return (
    <section className={classes}>
      <h2 className="feature-description__header">{t(header)}</h2>
      <p className="feature-description__description">
        <Trans i18nKey={description} />
      </p>
      {descriptionList && (
        <ul className="feature-description__description-list">
          {descriptionList.map((el, index) => (
            <li
              key={index}
              className="feature-description__description-list-item"
            >
              <span>
                <Trans i18nKey={el} />
              </span>
            </li>
          ))}
        </ul>
      )}
      {footer && <p className="feature-description__footer">{footerContent}</p>}
      <>{children}</>
    </section>
  );
};

export default FeatureDescription;
