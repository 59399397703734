import classNames from 'classnames';

interface Props {
  squareSideLengthRelativeToScreen?: number;
  positionHorizontal?: 'center' | 'left' | 'right';
  positionVertical?: 'center' | 'top' | 'bottom';
}

const GlowingSquare = (props: Props) => {
  const {
    squareSideLengthRelativeToScreen = 50,
    positionHorizontal = 'center',
    positionVertical = 'center',
  } = props;

  const positionClass = classNames('glowing-square', {
    'glowing-square--horizontal-center': positionHorizontal === 'center',
    'glowing-square--horizontal-left': positionHorizontal === 'left',
    'glowing-square--horizontal-right': positionHorizontal === 'right',
    'glowing-square--vertical-center': positionVertical === 'center',
    'glowing-square--vertical-top': positionVertical === 'top',
    'glowing-square--vertical-bottom': positionVertical === 'bottom',
  });

  return (
    <div
      className={positionClass}
      style={{
        width: `min(${squareSideLengthRelativeToScreen}vh, calc(100vw / 1.5))`,
        height: `min(${squareSideLengthRelativeToScreen}vh, calc(100vw / 1.5))`,
        maxWidth: '500px',
        maxHeight: '500px',
      }}
    >
      <div className="glowing-square__inner" />
    </div>
  );
};

export default GlowingSquare;
